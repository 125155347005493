import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import cn from "classnames";
import styles from "./block.module.scss";
import Icon from 'customElements/icon/icon';
import {PortfoliosBlockType} from "../../pages/Portfolio/Portfolio";


const PortfolioBlock = ({
                            name,
                            slug,
                            image,
                            text_short,
                        }: PortfoliosBlockType) => {
    const contentRef = useRef<HTMLDivElement>(null);

    // todo fix this code because it leaves only one character from the initial text
    // useEffect(() => {
    //     const truncateText = () => {
    //         const el = contentRef.current;
    //         if (!el) return;
    //
    //         const truncate = (text: string) => {
    //             while (el.scrollHeight > el.clientHeight) {
    //                 el.textContent = text.replace(/\W*\s(\S)*$/, '...');
    //                 text = el.textContent || '';
    //             }
    //         };
    //
    //         if (el && text_short.length > 0) {
    //             el.textContent = text_short[0];
    //             truncate(text_short[0]);
    //         }
    //     };
    //
    //     truncateText();
    //     window.addEventListener('resize', truncateText);
    //
    //     return () => window.removeEventListener('resize', truncateText);
    // }, [text_short]);

    return (
        <Link to={`/portfolio/${slug}`}>
            <div className={`${cn(styles.main)} p-4 d-flex flex-column align-items-center gap-3`}>
                <div className={`w-100 ${cn(styles.main_media)} d-flex justify-content-center`}>
                    <Icon width='100%' height='auto' img={image || ''} />
                </div>
                <div className={`${styles.main_info} d-flex flex-column gap-2`}>
                    <span>{name}</span>
                    <span ref={contentRef}>{text_short}</span>
                </div>
            </div>
        </Link>
    );
}

export default PortfolioBlock;