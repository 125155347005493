// import cn from "classnames"
import { HTMLAttributes } from "react"
import cn from 'classnames'
import styles from "./Projects.module.scss"
import { Link } from "react-router-dom";
import Icon from "customElements/icon/icon"
import Blocks from "customElements/developmentBlocks/blocks"
import instant from "../../assets/media/ideasInstant.svg"
import keepCash from "../../assets/media/ideasCash.svg"
import subs from "../../assets/media/ideasSubs.svg"
import arrow from '../../assets/media/arrow.png'
export const Projects: React.FC = () => {
  

  const blocks = [
    {title: "Define Scope", text: "Initiate the project by precisely outlining the app's objectives, target audience, and key features. Establish a clear project direction.", blockNumber: 1},
    {title: "Design and Plan", text: "Craft a visual prototype for the app and validate it with users. Setup a comprehensive project timeline to ensure smooth delivery", blockNumber: 2},
    {title: "Develop", text: "Execute the coding phase with a commitment to industry standards, utilizing latest technologies and frameworks", blockNumber: 3},
    {title: "Test", text: "Conduct thorough testing across units, integrations, and user acceptance to ensure the app's reliability and performance. ", blockNumber: 4},
    {title: "Deploy", text: "Optimize performance, select an appropriate hosting platform, and configure infrastructure to transition the app into a live environment.", blockNumber: 5},
    {title: "Update", text: "Consistently monitor performance, promptly resolve issues, and actively collect user feedback for iterative enhancements. ", blockNumber: 6}
   ]

  return (
     <section className={styles.projetcs} data-scroll-section="/projects" id="/projects">
        <div className={`contentPositionerVertical ${cn(styles.container)}`}>
           <div className={`${styles.ideas} position-relative`}>
              <div className="w-100 row d-flex justify-content-between gap-md-0 gap-4">
                <div className="col-lg-5">
                  <span className={styles.title}>
                    Turning your app ideas into reality
                  </span>
                </div>
                <div className={`col-lg-4 d-flex flex-column gap-3 ${styles.info_content}`}>
                  <span>
                    Explore a transformative collection of mobile apps that redefine the user experience:
                  </span>
                  <div className={`d-flex gap-2 align-items-end ${styles.see_more}`}>
                    <Link to={'/portfolio'}>
                      <span>See more</span>
                      <Icon img={arrow} width="20px" height="20px"/>
                    </Link>
                  </div>
                </div>
              </div>
              <div className={`${styles.ideas_container} row gy-4`}>
                  <div className="col-sm-4"><img src={instant} /></div>
                  <div className="col-sm-4"><img src={keepCash} /></div>
                  <div className="col-sm-4"><img src={subs} /></div>
              </div>
           </div>
        </div>
        
        <div className={`${styles.development} contentPositioner contentPositionerVertical`}>
          <div >
            <div className={`${styles.development_title}`}>
              <span>Start Your App Development Journey: </span>
            </div>
            <div className={`${styles.development_content}`}>
              <ul className="row gy-md-0 gy-3 justify-content-between">
                {blocks.map(({title, text, blockNumber},i) => 
                  <li  key={i} className="col-md-4">
                    <Blocks  title={title} text={text} blockNumber={blockNumber}/>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
  )
}
