import React from "react";

import styles from "./blocks.module.scss"
import blockImage from "../../assets/media/block1.svg"

type BlockProps = {
    title: string
    text: string
    blockNumber: number | undefined
  }

const Blocks: React.FC<BlockProps> = ({title, text, blockNumber}) => {

   return (
    <div className="d-flex flex-column">
        <div className="w-100 d-flex align-items-baseline gap-md-3 gap-4">
            <div className={styles.blocks_numbers}>
                <img src={blockImage} />  
                <span>{blockNumber}</span>
            </div>
        
            <div className={`${styles.info_content} d-flex flex-column gap-md-3 gap-2`}>
                <hr />
                <span>
                    {title}
                </span>
                <span>
                    {text}
                </span>
            </div>
        </div>
    </div>
   )
}

export default Blocks