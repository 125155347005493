import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styles from './DropDown.module.scss';

type DynamicObject = {
  [key: string]: any;
};

type DropdownProps = {
  items: DynamicObject[];
  label?: string;
  action?: React.MouseEventHandler<HTMLLIElement> | undefined
};

const Dropdown: React.FC<DropdownProps> = ({ items, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdown} onClick={toggleDropdown}>
      {label && <label className={`${styles.dropdown_label} d-flex gap-1 align-items-center`}>{label}
      <span className={`${styles.dropdown_arrow} ${isOpen ? styles.open : ''}`}>&#9660;</span>
      </label>}
      {isOpen && (
        <ul className={styles.dropdown_list}>
          {items.map((item, index) => (
            <Link to={item.address}>
              <li key={index} className={styles.dropdown_item} onClick={() => handleItemClick(item.title)}>
                {item.title}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;