// UseFulBlocks.tsx
import React from 'react';
import {Link} from 'react-router-dom';
import cn from "classnames"
import styles from "./UseFulBlocks.module.scss";
import Icon from 'customElements/icon/icon';
import {ArticlesBlockType} from "../../pages/BlogMenu";


function UseFulBlocks({
            name,
            slug,
            image,
            text_short,
            blogMenu
        }: ArticlesBlockType & {blogMenu?: boolean}) {
    return (
        <Link to={`/blog/${slug}`}>
            <div
                className={`${cn(styles.main, !blogMenu && styles.blog_menu)} p-4 d-flex flex-column align-items-center gap-3`}>
                <div
                    className={`w-100 ${cn(styles.main_media, blogMenu && styles.blog_menu)} d-flex justify-content-center`}>
                    <Icon width='auto' height='auto' img={image}/>
                </div>
                <div className='d-flex flex-column gap-2'>
                    <span className='lh-base'>{name}</span>
                    <span>{text_short}</span>
                </div>
            </div>
        </Link>
    );
}

export default UseFulBlocks;
