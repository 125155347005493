import React from "react";
import cn from "classnames";
import styles from "./Testimonials.module.scss";

import Blocks from "customElements/feedbackBlocks/blocks";

import Konstantin from "../../assets/media/feedbackKonstantin.svg";
import Maia from "../../assets/media/feedbackMaria.svg";
import Kostas from "../../assets/media/feedbackKostas.svg";

export const Testimonials: React.FC = () => {
  const feedbacks = [
    {
      image: Konstantin,
      name: "Konstantin Moroshin",
      profession: "Co-Founder - BrandmeApp",
      feedback:
        "Armara has shown incredible responsibility, flexibility, and dedication in this project. I would especially like to note that this was a complex project with an atypical architecture inherited from past developers, and the team did an excellent job of developing this application."
    },
    {
      image: Maia,
      name: "Maia Caranciuc",
      profession: "Finance and Operations Manager at Index",
      feedback:
        "I have been working with Armara for over a year, and the team has performed exceptionally well so far, consistently delivering exceptional code quality. Having Armara means you can trust that new features and issues will be well managed without any concern."
    },
    {
      image: Kostas,
      name: "Kostas Melas",
      profession: "CTO at Access Experiences Ltd",
      feedback:
        "Great work from Armara, they were quick to pick up the project and deliver results. Looking forward to working with them again in the future."
    }
  ];

  return (
    <section
      className={cn(styles.component, "contentPositionerVertical")}
      data-scroll-section="/testimonials"
      id="/testimonials"
    >
      <div className={`contentPositioner`}>
        <div className={`${styles.main} d-flex flex-column`}>
          <span>What people say about us</span>
            <ul className="row p-0">
              {feedbacks.map(({ image, name, profession, feedback }, i) => (
                <li
                  key={i}
                  className="p-0 col-sm-12 col-md-6 col-xxl-4"
                >
                  <Blocks
                    name={name}
                    peopleImage={image}
                    profession={profession}
                    feedback={feedback}
                  />
                </li>
              ))}
            </ul>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
