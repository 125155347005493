import React, { useState } from "react"

interface IconProps {
  img: string,
  md?: boolean | number,
  sm?: boolean | number,
  height?: string,
  width?: string,
  white?: boolean,
  hover?: boolean,
  disabled?: boolean,
  alt?: string,
  action?: React.MouseEventHandler<HTMLImageElement> | undefined,
}

const Icon: React.FC<IconProps> = ({ img, md, sm, height, width, white, hover, disabled, alt, action }) => {
  const [isHover, setHover] = useState(false)

  const iconStyles = {
    height: `${md ? "43px" : sm ? "40px" : height ? height : "64px"}`,
    width: width ? width : "auto",
    display: "inline-block",
    cursor: !disabled ? "pointer" : "inherit",
  }

  const colorStyles = {
    filter: (white && isHover) || hover ? "brightness(0) invert(1)" : "",
  }

  return (
    // eslint-disable-next-line
    <img
      src={img}
      alt={alt || "icon"}
      style={{ ...iconStyles, ...colorStyles }}
      onClick={(action ? action : "") || undefined}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    />
  )
}

export default Icon
