import React from "react";

import styles from "./blocks.module.scss"

import aboutLogo from "../../assets/media/aboutIcons.svg"
import backGround from "../../assets/media/aboutUs.png"

type BlockProps = {
    name: string
    profession: string
    feedback: string
    peopleImage: string | undefined
  }

const Blocks: React.FC<BlockProps> = ({name, profession, feedback, peopleImage}) => {

   return (
    <div className={`${styles.custom_component} d-flex align-items-center gap-3`}>
        <div className={`${styles.main}`}>
            <div className={`${styles.content} `}>
                <img src={aboutLogo} />
                <div className={`mt-3 mt-md-4 ${styles.content_people_info} d-flex gap-2`}>
                    <img src={peopleImage}/>
                    <div className="d-flex flex-column gap-2">
                        <span>{name}</span>
                        <span>{profession}</span>
                    </div>
                </div>
                <div className={`mt-4 mt-md-5 ${styles.content_feedback}`}>
                    <span >{feedback}</span>
                </div>
            </div>
        </div>
        
    </div>
   )
}

export default Blocks