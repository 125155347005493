import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import ReloadComponent from '../components/RealoadComponent/RealoadComponent';

const ServiceComponent = React.lazy(() => import('../pages/Services/Service/index'));

const DynamicService = () => {
  const { serviceName } = useParams();

  // Logic to determine which service content to render
  const renderServiceContent = () => {
    switch (serviceName) {
      case 'android':
      case 'ios':
      case 'cross':
      case 'mvp':
      case 'mobile':
      case 'redevelopment':
      case 'support':
        return <ServiceComponent serviceType={serviceName} />;
      default:
        return <div>Service not found</div>;
    }
  };

  return (
    <Suspense fallback={<div><ReloadComponent /></div>}>
      {renderServiceContent()}
    </Suspense>
  );
};

export default DynamicService;