

import React, { Suspense } from 'react';
import { Header } from 'components/Header/Header';
import { Footer } from 'components/Footer/Footer';
import { RouteObject } from 'react-router-dom';
import { AppRoutes } from 'utils/constants';
import { useRoutes } from 'react-router-dom';
import Outlet from 'components/Outlet/Outlet';
import { Contact } from 'pages/Contact/Contact';
import { Privacy } from 'pages/Privacy/Privacy';
import ReloadComponent from 'components/RealoadComponent/RealoadComponent';
import BlogMenu from 'pages/BlogMenu/index';
import Portfolio from 'pages/Portfolio/Portfolio';
import Portfolios from 'pages/SinglePortfolio/index';

// Lazy-loaded components
const Articles = React.lazy(() => import('pages/SingleArticles/index'));
import DynamicService from './routes/servicesRoutes.jsx'
import {AboutUs} from "./pages/AboutUs/AboutUs";

function App() {
  const appRoutes: RouteObject[] = [
    {
      path: AppRoutes.Home,
      element: <Outlet />,
    },
    {
      path: '/about-us',
      element: <AboutUs />,
    },
    {
      path: AppRoutes.Privacy,
      element: (
        <Suspense fallback={<div><ReloadComponent/></div>}>
          <Privacy/>
        </Suspense>
      ),
    },
    {
      path: AppRoutes.Articles,
      element: (
        <Suspense fallback={<div><ReloadComponent/></div>}>
          <Articles />
        </Suspense>
      ),
    },
    {
      path: '/services/:serviceName',
      element: (
        <Suspense fallback={<div><ReloadComponent/></div>}>
          <DynamicService />
        </Suspense>
      ),
    },
    {
      path: '/blog/:slug',
      element: (
        <Suspense fallback={<div><ReloadComponent/></div>}>
          <Articles />
        </Suspense>
      ),
    },
    {
      path: AppRoutes.BlogMenu,
      element: (
        <Suspense fallback={<div><ReloadComponent/></div>}>
          <BlogMenu />
        </Suspense>
      ),
    },
    {
      path: AppRoutes.Portfolio,
      element: (
        <Suspense fallback={<div><ReloadComponent/></div>}>
          <Portfolio />
        </Suspense>
      ),
    },
    {
      path: '/portfolio/:slug',
      element: (
          <Suspense fallback={<div><ReloadComponent/></div>}>
            <Portfolios />
          </Suspense>
      ),
    },
  ];

  return (
    <>
      <Header />
      {useRoutes(appRoutes)}
      <Contact/>
      <Footer />
    
    </>
  );
}

export default App;
