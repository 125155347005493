
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

import { AppRoutes } from "utils/constants";
import Dropdown from "customElements/dropdown";
import { services } from '../../utils/data/Services/ServiceBlocks'

const NavigationLinks = ({ activeLink = 'home', style }) => {
const navigate =useNavigate()
const location = useLocation();
const currentPath = location.pathname;

  const links = [
    { route: AppRoutes.Home, label: "Home" },
    { route: AppRoutes.Projects, label: "Projects" },
    { route: AppRoutes.BlogMenu, label: "Insights" },
    { route: AppRoutes.AboutUs, label: "About us" },
    { route: AppRoutes.Services, label: "Services" },
    // { route: AppRoutes.Testimonials, label: "Testimonials" },
    // { route: AppRoutes.FAQs, label: "FAQs" },
    // { route: AppRoutes.Contact, label: "Contacts" },
  ];

  const serviceItems = [
    { route: AppRoutes.Services, label: "Android App development" },
    { route: AppRoutes.Services, label: "iOS App development" },
    { route: AppRoutes.Services, label: "Cross-platform App development" },
    { route: AppRoutes.Services, label: "MVP development" },
    { route: AppRoutes.Services, label: "Mobile App redevelopment" },
    { route: AppRoutes.Services, label: "Mobile App Support" },
  ];


  return (
    <ul className={style.list}>
      {links.map((link, index) => {
        return (
          link.route === '/services' ? 
            <li key={index}>
              <Dropdown 
                items={services}
                label="Services" 
              />
            </li>
          : link.label === 'Projects' ? 
            <li key={index}> <Link to={'/portfolio'}>{link.label}</Link></li>
          : link.label === 'About us' ?
            <li key={index}> <Link to={'/about-us'}>{link.label}</Link></li>
          : link.label === 'Insights' ?
            <li key={index}> <Link to={'/blog'}>{link.label}</Link></li>
          : <li key={index}>
            {currentPath !== '/' ? 
              <a onClick={() => navigate('/', { state: { elementRef: link.route } })}>
                {link.label}
              </a> 
              :
              <ScrollLink 
                to={link.route}
                className={activeLink === link.route ? style.active : ""}
                scroll={el => scrollWithOffset(el)}
                smooth={true}
              >
                {link.label}
              </ScrollLink>
            }
          </li>
          
        );
      })}

      
    </ul>
  );
};

export default NavigationLinks;
