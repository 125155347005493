import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useScrollRouting = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(
        localStorage.getItem("activeLink") || "/"
    );

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("[data-scroll-section]");
            const scrollY = window.scrollY;
            const scrollOffset = Math.max(0, scrollY + 100);
              
            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (
                    scrollOffset >= sectionTop &&
                    scrollOffset < sectionTop + sectionHeight
                ) {
                    const newActiveLink = section.getAttribute("data-scroll-section");
                    setActiveLink(newActiveLink);
                    localStorage.setItem("activeLink", newActiveLink);
                }
            });
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        // Update active link when location changes
        setActiveLink(location.pathname);
    }, [location]);

    return activeLink;
};

export default useScrollRouting;
