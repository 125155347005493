import React, {useRef, useEffect} from "react"
import { iframeResizer } from "../../utils/clutch"
import cn from "classnames"

import { useElement } from '../../utils/ElementContext';
import styles from "./Home.module.scss"
import design from "../../assets/media/yourDesign.svg"
import badg from "../../assets/media/hexagonal.svg"
import badg2 from "../../assets/media/badge2.jpeg"
import TB_trusted from "../../assets/media/TB-trusted.svg"
import Button from "../../customElements/button/button"
import Icon from "../../customElements/icon/icon"
import useWidth from "hooks/useWidth";


export const Home: React.FC = () => {
  const elementRef = useElement();
  const [_,isMobile]=useWidth() 

  useEffect(() => {
    iframeResizer();
    const w = window as any;
    if(!w?.CLUTCHCO?.loaded) {
      w?.CLUTCHCO?.Init();
    }
  }, []);

  const handleClick = () => {
    elementRef.current && elementRef.current.scrollIntoView({behavior: "smooth"});
  };

  return (
    <section className={cn(styles.main, 'contentPositioner contentPositionerVertical')} data-scroll-section="/" id="/">
      <div className="contentPositionerTop row d-flex flex-lg-row flex-column gap-5 gap-lg-0 justify-content-between">
        <div className="col-lg-7">
          <div className={`${styles.info_content} d-flex flex-column gap-4`}>
            <span className={styles.title}>Full-service mobile app development company</span>
            <div className={`mt-2 mb-3 ${styles.text} d-flex flex-column gap-4`}>
              <span className={styles.span}>
                  Armara is a mobile app development company dedicated to delivering innovative and tailored mobile solutions. 
              </span>
              <span>
                Our experienced group of developers and designers collaborates closely with clients to transform ideas into robust,
                user-friendly mobile applications.
              </span>
            </div>
            <div className="d-flex flex-column gap-5">
              <Button sm={isMobile} primary width="229px" action={handleClick}>
                Get a Free Consultation
              </Button>
              {/* Badges */}
              <div className="row gy-md-0 gy-4 mt-3 d-flex align-items-center flex-md-row ">
                <a className="col-6 col-md-3" href="https://www.designrush.com/agency/profile/armara" target="_blank">
                  <Icon alt={'Armara on DesignRush'} width={isMobile ? '100px' : '136px'} height={isMobile ? '100px' : "136px"}  img={badg} />
                </a>
                <a className="col-6 col-md-3" href="https://superbcompanies.com/categories/mobile-app-development-companies/" target="_blank">
                  <Icon alt={'Armara on DesignRush'} width={isMobile ? '100px' : '136px'} height={isMobile ? '100px' : "136px"} img={badg2} />
                </a>
                <a className="col-6 col-md-3" href="https://techbehemoths.com/company/armara" target="_blank">
                  <Icon alt={'Armara on DesignRush'} width={isMobile ? '100px' : '136px'} height={isMobile ? '100px' : "136px"}  img={TB_trusted} />
                </a>
                <div className="clutch-widget col-6 col-md-3"
                      data-url="https://widget.clutch.co" 
                      data-widget-type="1" 
                      data-height="40" 
                      data-nofollow="true" 
                      data-expandifr="true" 
                      data-scale="100" 
                      data-clutchcompany-id="2302323">
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && 
        <div className="col-lg-5">
          <div className={`${styles.media_content}`}>
            <img src={design} height={460} />
          </div>
        </div>
        }
      </div>
    </section>
  )
}
