import React from 'react';
import reload from "../../assets/media/icons8-refresh.gif";
import style from "./RealoadComponent.module.scss"

function ReloadComponent() {
  return (
    <div className={style.reload}>
      <img src={reload} alt="Reload Icon" />
    </div>
  );
}

export default ReloadComponent;
