import React, { useEffect, useRef } from "react";
import styles from "./Services.module.scss";
import Blocks from "customElements/servicesBlocks/blocks";
import { services } from '../../utils/data/Services/ServiceBlocks' 
import eventEmitter from '../../utils/eventEmitter.js';

export const Services: React.FC = () => {
  const sectionRef = useRef<HTMLUListElement>(null); // Corrected useRef type

  useEffect(() => {
    eventEmitter.emit('ServicesMounted', sectionRef.current);
  }, []);

  return (
    <section className={`${styles.component} my-4 contentPositioner contentPositionerVertical contentPositionerTop`} data-scroll-section="/services" id="/services">
      <div className={`${styles.main} d-flex flex-column align-items-center`}>
        <div className={`${styles.main_text_info} d-flex flex-column gap-3`}>
          <span>App Development Services</span>
          <span>At Armara, we rapidly transform ideas into top-notch Android and iOS apps, driven by our agile approach</span>
        </div>
        <div className={`${styles.main_services}`}>
          <ul ref={sectionRef} className="row gx-4 ">
            {services.map(({id, title, text, blockIcon, address}, i) => 
              <li key={i} className="col-xl-4 col-md-6" id={id} >
                <Blocks title={title} text={text} blockIcon={blockIcon} address={address}/>
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
}
