import React, {useEffect} from "react"
import cn from "classnames"
import styles from "./AboutUs.module.scss"
import Button from "customElements/button/button"
import aboutLayer from "../../assets/media/aboutLayer.svg"
import connect from "../../assets/media/aboutLogoconnect.svg"
import aboutLogo from "../../assets/media/aboutLogo.svg"
import map from "../../assets/media/map.svg"
import useWidth from "hooks/useWidth"
import { useElement } from "utils/ElementContext"

export const AboutUs: React.FC = () => {
  const [_,isMobile]= useWidth(1200)
  const elementRef = useElement();

  const handleClick = () => {
    elementRef.current && elementRef.current.scrollIntoView({behavior: "smooth"});
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  return (
    <>
      <section className={cn(styles.component, "contentPositionerVertical")} data-scroll-section="/about-us" id="/about-us">
        <div className={`contentPositioner`}>
          <div className={`${styles.experience} row`}>
            <div className={`${styles.experience_globally} col-md-8 d-flex flex-column`}>
              <span>
                Crafting exceptional mobile experiences globally
              </span>
              <div className={styles.experience_globally_content}>
                <img src={map} />
              </div>
            </div>
            <div className={`${styles.experience_types} mt-md-0 mt-5 col-md-4 d-flex flex-column gap-5`}>
              <div className="d-flex flex-column gap-2 gap-md-3">
               <span>Years of Experience</span>
               <span>10+</span>
               <span>With a robust 10 years in the industry, Armara stands as a beacon of development experience, navigating the dynamic landscape of mobile solutions.</span>
              </div>
              <div className="d-flex flex-column gap-2 gap-md-3">
                <span>Projects Completed</span>
                <span>30+</span>
                <span>Armara has crafted over 30 apps across different industries, highlighting our adaptability and expertise in overcoming various technological challenges.</span>
              </div>
              <div className="d-flex flex-column gap-2 gap-md-3">
                <span>Team Composition</span>
                <span>15+</span>
                <span>A team of 15+ skilled professionals, including developers, designers, QA specialists, and project managers.</span>
              </div>
            </div>
          </div>
       </div>
      </section>
    </>
  )
}
