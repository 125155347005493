import React from "react";
import styles from "./blocks.module.scss";
import { Link } from "react-router-dom";

type BlockProps = {
  title: string; 
  text: string;
  address: string; // Corrected prop name
  blockIcon: string | undefined;
}

const Blocks: React.FC<BlockProps> = ({title, text, blockIcon, address}) => {
  return (
    <Link to={address}>
      <div className={`${styles.main} d-flex flex-column align-items-center gap-3`}>
        <div className={`${styles.main_media}`}>
          <img src={blockIcon} alt={title} /> {/* Added alt attribute */}
        </div>
          <span>{title}</span>
          <span>{text}</span>
      </div>
    </Link>
  );
}

export default Blocks;