import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ElementProvider } from './utils/ElementContext'
import "assets/styles/index.scss"
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "App"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <ElementProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </ElementProvider>
)
