import android from "../../../assets/media/servicesAndroid.svg";
import ios from "../../../assets/media/ServicesMVP.svg";
import redevelopment from "../../../assets/media/servicesRedev.svg"; 
import mobileSupport from "../../../assets/media/servicesSupport.svg";
import cross from "../../../assets/media/servicesCross.svg";
import MVP from "../../../assets/media/ServicesMVP.svg";

export const services = [
    {title: "Android App Development", text: "Craft custom Android applications using Kotlin and Java, ensuring high performance and compliance to Material Design guidelines.", blockIcon: android, id: 'services/android', address:"services/android"},
    {title: "iOS App Development", text: "Develop tailored iOS applications using Swift and Objective-C, adhering to Apple's guidelines and leveraging the latest iOS features.", blockIcon: ios, id: 'services/ios', address:"services/ios"},
    {title: "Cross-Platform Development", text: "Create cross-platform mobile applications using frameworks like React Native or Flutter, ensuring cost-effectiveness and faster time-to-market.", blockIcon: cross, id: 'services/cross', address:"services/cross"},
    {title: "MVP Development", text: "Create a Minimum Viable Product (MVP), allowing you to validate your app concept quickly and efficiently.", blockIcon: MVP, id: 'services/mvp', address:"services/mvp"},
    {title: "App Redevelopment", text: "Revamp old applications, introducing new features and functionality to improve performance, safety and user experience.", blockIcon: redevelopment, id: 'services/mobile', address:"services/redevelopment"},
    {title: "Mobile App Support", text: "Optimize your mobile app's performance with our  real-time assistance, bug identification and fixes, ensuring seamless updates.  ", blockIcon: mobileSupport, id: 'service/support', address:"services/support"},
  ];