import React, {useEffect, useState} from 'react'
import useWidth from 'hooks/useWidth'
import { useNavigate } from "react-router-dom";
import cn from "classnames"
import styles from "./Menu.module.scss"
import Icon from 'customElements/icon/icon'
import rectangle from "../../assets/media/Rectangle_177.svg"
import tabs from "../../assets/media/blog.jpg"
import UseFulBlocks from 'customElements/UseFulBlocks/UseFulBlocks'

import {useFulBlocks} from "../../utils/data/BlogMenu"

export type ArticlesBlockType = {
  name: string,
  slug: string,
  image: string,
  text_short: string,
  title?: string,
  description?: string,
  text?: string,
}

function BlogMenu() {
  const [_, isMobile] = useWidth();
  const navigate = useNavigate();
  const [articleBlockData, setArticleBlockData] = useState<ArticlesBlockType[]>([]);
  
  useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });

      fetch('https://backend.armara.dev/api/blogs')
        .then(response => response.json())
        .then(data => setArticleBlockData(data.data))
  }, []);

  return (
    <>
    <section className={` ${cn(styles.main, 'contentPositioner contentPositionerVertical')} `}>
      <div className="contentPositionerTop pt-1 mt-5 row d-flex flex-lg-row flex-column gap-5 gap-lg-0 justify-content-between">
        <div className={`${styles.main}`}>

          <div className={`w-100 ${styles.main_picture}`}></div>
          <div className={`${styles.main_usefuls} d-flex flex-column align-items-center`}>
            <div className="mb-5 mt-0 mt-md-3 pt-0 pt-md-3 row gx-4 gy-4">
              {articleBlockData.map(({
                                       name,
                                       slug,
                                       text_short,
                                       image
                                     }) =>
                  <div className="col-md-4">
                    <UseFulBlocks blogMenu name={name} slug={slug} image={image} text_short={text_short}/>
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default BlogMenu