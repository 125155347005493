import React, { useState } from "react";

interface Props {
  sm?: boolean | number;
  link?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  width?: string;
  screenWidth?:number;
  children?: React.ReactNode;
  action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  hoverAction?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  primary?: boolean;
  secondary?: boolean;
  light?: boolean;
  dark?: boolean;
  border?: string;
}

const Button: React.FC<Props> = ({
  children,
  sm,
  type,
  link,
  disabled,
  width,
  action,
  hoverAction,
  primary,
  secondary,
  light,
  dark,
  border,
}) => {
  const [hover, setHover] = useState(false);
  

  const [primaryBG, secondaryBG, lightBG, darkBG, disabledBG] = [
    "#7F00E2",
    "#FFC93B",
    "#FFF",
    "#18142D",
    "#6c757d",
  ];
  const [primaryColor, secondaryColor] = ["#FFF", "#18142D"];

  const getBG = () =>
    disabled
      ? disabledBG
      : primary
      ? primaryBG
      : secondary
      ? secondaryBG
      : light
      ? lightBG
      : dark
      ? darkBG
      : "none";
  const getTextColor = () => (light || secondary ? secondaryColor : primaryColor);

  const buttonStyles: React.CSSProperties = {
    height: sm ? 48 : 72, 
    width: width || `100%`,
    padding: !link ? "10px 16px" : "",
    background: getBG(),
    color: getTextColor(),
    borderRadius: 7,
    border: border || "none",
    fontSize: 16,
    lineHeight: "145%",
    fontWeight: 500,
    cursor: !disabled ? "pointer" : "auto",
    textAlign: link ? "start" : "center",
  };

  return (
    <>
      <button
        type={type}
        disabled={disabled}
        style={{ ...buttonStyles }}
        onMouseEnter={hoverAction && !disabled ? hoverAction : () => setHover(true)}
        onMouseLeave={hoverAction && !disabled ? hoverAction : () => setHover(false)}
        onClick={!disabled ? action : undefined}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
