import React, {useEffect, useState} from 'react'
import useWidth from 'hooks/useWidth'
import { useNavigate } from "react-router-dom";
import cn from "classnames"
import styles from "./Portfolio.module.scss"
import Icon from 'customElements/icon/icon'
import PortfolioBlock from 'customElements/portfolioBlock/index'
import banner from "../../assets/media/portfolioBanner.svg"

import { portfolioBlocks } from "../../utils/data/PortfolioBlocks"

export type PortfoliosBlockType = {
    name?: string,
    slug?: string,
    image?: string,
    text_short?: string,
    title?: string,
    description?: string,
    challenge?: string,
    solution?: string,
    result?: string,
}

function Portfolio() {
  const [_, isMobile] = useWidth();
  const navigate = useNavigate();
  const [ portfolioBlockData, setPortfolioBlockData ] = useState<PortfoliosBlockType[]>([]);

  useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });

      fetch('https://backend.armara.dev/api/projects')
          .then(response => response.json())
          .then(data => setPortfolioBlockData(data.data))
  }, []);

  return (
    <section className={` ${cn(styles.main, 'contentPositioner contentPositionerVertical')} `}>

        <div className="contentPositionerTop pt-1 mt-5 row d-flex flex-lg-row flex-column gap-5 gap-lg-0 justify-content-between">

            <div className={`${styles.main} mt-5 mt-md-0`}>

                <div className={`${styles.main_usefuls} d-flex flex-column align-items-center`}>
                <div className={`w-100 ${styles.main_bunner}`}></div>
                    <div className="mb-3 mt-0 pt-0 row gx-4 gy-4">
                        {portfolioBlockData.map(({name,
                                                   slug,
                                                   text_short,
                                                   image}) =>
                        <div className="col-md-4">
                            <PortfolioBlock name={name} slug={slug} image={image} text_short={text_short}/>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Portfolio