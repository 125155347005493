import React from 'react';
import styles from "./input.module.scss"

interface InputProps {
  label?: string;
  id?: string,
  name?: string,
  placeholder?: string,
  width?: string;
  action?: React.ChangeEventHandler<HTMLInputElement>;
  actionCheckBox?: React.MouseEventHandler<HTMLInputElement>;
  areaAction?: React.ChangeEventHandler<HTMLTextAreaElement>
  blur?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readonly?: boolean;
  value?: string;
  checked?: boolean,
  error?: boolean;
  errorMessage?: string;
  sm?: boolean,
  smallLabel?: boolean;
  password?: boolean,
  border?: string,
  fontSize?: string,
  backGround?: string,
  close?: boolean,
  textarea?: boolean,
  checkbox?: boolean,
}

const Input: React.FC<InputProps> = ({ label, id, name, smallLabel, placeholder, width, sm, disabled, readonly, value, checked, error, errorMessage, action, areaAction, blur, actionCheckBox, border, backGround, fontSize, textarea, checkbox}) => {
 
  const labelStyles: React.CSSProperties = {
    fontSize: smallLabel ? 12 : 16,
    fontWeight: 300,
    color: error ? '#F21A1A' : '#FAFAFA',
    marginBottom: 8
  }
  
  return (
    <>
      <div style={{width: width}}>
        {label && <label htmlFor="input-field"
          className="form-label"
          style={labelStyles}>
            {label}
        </label>}
          {textarea ? 
              <textarea 
                className={`form-control ${styles.custom_input}`}
                style={{
                  color: '#FAFAFA', 
                  height: sm ? 36 : 148, 
                  fontSize: fontSize || 16, 
                  background: backGround || '#07050F', 
                  border: border || '1px solid rgba(255, 255, 255, 0.50)',
                  width: "100%"
              }}
                  value={value}
                  id={id}
                  name={name}
                  onChange={areaAction}
                  placeholder={placeholder || 'Placeholder'}
              >
              </textarea>
              : 
              checkbox ? 
              <input className={`${styles.custom_checkbox} form-check-input`} type="checkbox"
                      id={id}
                      name={name}
                      checked={checked}
                      onClick={actionCheckBox}
                      onBlur={blur}
                      placeholder={placeholder || 'Placeholder'}
                      disabled = {disabled}
                      readOnly = {readonly} /> 
              :
              <input
                  type="text"
                  className={`form-control ${styles.custom_input}
                                    ${readonly ? styles.readonly : ''}
                                    ${!disabled ? styles.hovered : styles.disabled}
                                    `}
                  style={{
                    color: 'rgb(250 250 250 / 70%)', 
                          height: sm ? 36 : 48, 
                          fontSize: fontSize || 16, 
                          background: backGround || '#07050F', 
                          border: border || '1px solid rgba(255, 255, 255, 0.50)'
                        }}
                  id={id}
                  name={name}
                  value={value}
                  onChange={action}
                  onBlur={blur}
                  placeholder={placeholder || 'Placeholder'}
                  disabled = {disabled}
                  readOnly = {readonly}
              />
          }

        {error && (
          <div className={`${styles.invalid_feedback}`}>{errorMessage}</div> 
        )}
      </div>
    </>
  );

};

export default Input;
