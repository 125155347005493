import { useEffect, useState } from "react";

export default function useWidth(screenWidth = 992){
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setWidth(window.innerWidth);
        };

        // window.addEventListener("resize", handleResize);

        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);

    return [width, width < screenWidth];
}

