const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const phoneNumberRegex = /^\+?[\d\s().-]+$/;
const isEmailValid = (value) => regexEmail.test(value);
const isValidPhoneNumber = (value) => phoneNumberRegex.test(value);

export const Validate = (value, field, error) => {
  if (!value) {
    error.error = true;
    error.message = `${field.charAt(0).toUpperCase() + field.slice(1)} is Required`;
    if (field === 'privacy') {
      error.message = 'Kindly confirm your consent for personal data processing';
    }
  } else {
    switch (field) {
      // case 'phone': 
      // if(!value || !isValidPhoneNumber(value)) {
      //   error.error = true;
      //   error.message = 'Invalid Phone Number';
      // } else {
      //   error.error = false;
      //   error.message = '';
      // }
      // break;
      case 'email':
        if (!value || !isEmailValid(value)) {
          error.error = true;
          error.message = 'Invalid Email';
        } else {
          error.error = false;
          error.message = '';
        }
        break;
      default:
        if (!value) {
          error.error = true;
          error.message = `${field.charAt(0).toUpperCase() + field.slice(1)} is Required`;
        } else {
          error.error = false;
          error.message = '';
        }
    }
  }
};
