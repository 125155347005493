import React, { useEffect, useState } from 'react';
import styles from './Popup.module.scss'

const Popup = ({ isOpen, onClose, title, text }) => {

  const [isModalOpened, setIsModalOpened] = useState(isOpen);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalOpened(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isOpen, setIsModalOpened, isModalOpened]);

  const closePopup = () => {
   setIsModalOpened(false)
  }

  return (
    <div className={`${styles.popup} ${isModalOpened ? styles.open : styles.closed}`}>
      <div className={`d-flex flex-column gap-1 ${styles.popup_content}`}>
        <span className={styles.title}>{title}</span>
        <span className={styles.text}>{text}</span>
        {/* <Icon sm img={close} action={closePopup} /> */}
      </div>
    </div>
  );
};

export default Popup;