import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { AppRoutes } from "../../utils/constants";
import DropDown from 'customElements/dropdown'
import { services } from "utils/data/Services/ServiceBlocks";
import twoLog from "../../assets/media/logo.svg";
import style from "./leftMenu.module.scss";
import cn from "classnames";
import useScrollRouting from "routes/scrollRoute";

interface LeftMenuProps {
  active: boolean;
  setMenu: Function;
  imgRef: any;
}

const LeftMenu: React.FC<LeftMenuProps> = ({ active, setMenu, imgRef }) => {
  const navigate =useNavigate()
  const location = useLocation();
  const currentPath = location.pathname;
  const leftMenuRef = useRef<HTMLDivElement>(null);
  const activeLink = useScrollRouting();

  const arr = [
    { route: AppRoutes.Home, label: "Home" },
    { route: AppRoutes.Projects, label: "Projects" },
    { route: AppRoutes.BlogMenu, label: "Insights" },
    { route: AppRoutes.AboutUs, label: "About us" },
    { route: AppRoutes.Services, label: "Services" },
    // { route: AppRoutes.Testimonials, label: "Testimonials" },
    // { route: AppRoutes.FAQs, label: "FAQs" },
    // { route: AppRoutes.Contact, label: "Contact" },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !leftMenuRef.current?.contains(event.target as Node) &&
        event.target !== imgRef.current
      ) {
        setMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenu, imgRef]);

  const handleNavigateHome = (section: string) => {
    navigate('/', { state: { elementRef: section } });
    setMenu(false)
  }

  return (
    <div
      ref={leftMenuRef}
      className={`${style.mobileMenu} ${active ? style.active : ""}`}
    >
      <a href={"/"}
          target="_self"
          rel="noopener noreferrer">
            <img src={twoLog} alt="Logo" className={style.logo} />
      </a>
      <hr />
      <ul className={style.list}>
        {arr.map((item, index) => (
          item.route === '/services' ? 
          <li key={index}>
            <DropDown 
              items={services}
              label="Services" 
            />
          </li>
          :
          item.label === 'Projects' ? 
          <li key={index}> <Link to={'/portfolio'}>{item.label}</Link></li>
          : item.label === 'About us' ?
          <li key={index}> <Link to={'/about-us'}>{item.label}</Link></li>
          : item.label === 'Insights' ?
          <li key={index}> <Link to={'/blog'}>{item.label}</Link></li>
          :
          <li key={index}>
             {currentPath !== '/' ? 
              <a onClick={() => handleNavigateHome(item.route)}>
                {item.label}
              </a> 
              :
              <ScrollLink
                to={item.route}
                className={cn(activeLink === item.route ? style.act : "")}
                onClick={() => setMenu(false)}
              >
                {item.label}
              </ScrollLink>
            }
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftMenu;