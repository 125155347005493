import { createContext, useRef, useContext } from 'react';

const ElementContext = createContext();

export const ElementProvider = ({ children }) => {
  const elementRef = useRef(null);
  return (
    <ElementContext.Provider value={elementRef}>
      {children}
    </ElementContext.Provider>
  );
};

export const useElement = () => useContext(ElementContext);